interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
  paddingY?: number
}

export default function Container({ className, children, paddingY }: ContainerProps) {
  return (
    <div className={`container mx-auto py-${paddingY} w-10/12 ${className}`}>
      {children}
    </div>
  );
}

Container.defaultProps = {
  className: "",
  paddingY: 10
};