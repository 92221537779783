import Navbar from "./Navbar";

interface TemplateProps {
  children: React.ReactNode;
}

export default function Template({children}: TemplateProps) {
  return (
    <div className="bg-slate-100 dark:bg-zinc-800 flex flex-col h-screen">
      <Navbar/>
      {children}
    </div>
  );
}