import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux";
import Walks from "./components/walks/Walks";

// Redux
import { store } from "./store";
import WalkDetail from "./components/walks/WalkDetail";
import LatLongProvider from "./contexts/LatLongProvider";


function App() {
 
  return (
    <Provider store={store}>
      {/* <LatLongProvider> */}
      <Router>
        <Routes>
          {/* Walks - Public */}
          <Route path="/" element={<Walks />} />

          {/* Walk detail */}
            <Route
              path="/:walk_id"
              element={<WalkDetail />}
            />

          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </Router>
      {/* </LatLongProvider> */}
    </Provider>
  );
}

export default App;