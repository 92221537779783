import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../utils/proxy";

export interface WalkState {
  id: string;
  name: string;
  description: string;
  latitude: number;
  longitude: number;
  altitude: number;
  image: string;
  geojson_features: any;
}

export interface WaypointState {
  id: string;
  name: string;
  type: string;
  description: string;
  latitude: number;
  longitude: number;
  altitude: number;
  order: number;
  walk: string;
}

interface ContentState {
  id: string;
  name: string;
  description: string;
  content_type: string;
  content_text: string;
  url: string;
  waypoint: string;
}

// Define a type for the slice state
interface WalksState {
  walks: {
    data: WalkState[] | null;
    loading: boolean;
  },
  walk: {
    data: WalkState | null;
    loading: boolean;
  },
  waypoints: {
    data: WaypointState[] | null;
    loading: boolean;
    active: null | WaypointState;
  },
  content: {
    data: ContentState[] | null;
    loading: boolean;
  }
}

// Define the initial state using that type
const initialState: WalksState = {
  walks: {
    data: null,
    loading: false
  },
  walk: {
    data: null,
    loading: false
  },
  waypoints: {
    data: null,
    loading: false,
    active: null,
  },
  content: {
    data: null,
    loading: false
  }
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

export const getWalks = createAsyncThunk(
  "walks/get",
  async (arg, { getState, rejectWithValue }) => {
    try {

      // Configure authorization header
      const config = { headers: {} };

      // Retrieve auth data
      let res = await axios.get(`https://172-236-28-14.ip.linodeusercontent.com/api/walk/`, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getWalkById = createAsyncThunk(
  "walks/walk/get",
  async (obj: {
    walkId: string | undefined
  }, { getState, rejectWithValue }) => {
    try {

      // Configure authorization header with user's token
      const config = { headers: { } };

      const {walkId} = obj;
      const res = await axios.get(`https://172-236-28-14.ip.linodeusercontent.com/api/walk/${walkId}/`, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getWalkWaypoints = createAsyncThunk(
  "walk/waypoints/get",
  async (obj: {
    walkId: string | undefined
  }, { getState, rejectWithValue }) => {
    try {

      // Configure authorization header with user's token
      const config = { headers: { "Content-Type": "application/json" } };

      const {walkId} = obj;
      const body = JSON.stringify({id: walkId});
      const res = await axios.post(`https://172-236-28-14.ip.linodeusercontent.com/api/walk/waypoint/`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getWalkContent = createAsyncThunk(
  "walk/content/get",
  async (obj: {
    walkId: string | undefined
  }, { getState, rejectWithValue }) => {
    try {

      // Configure authorization header with user's token
      const config = { headers: { "Content-Type": "application/json" } };

      const {walkId} = obj;
      const body = JSON.stringify({id: walkId});
      const res = await axios.post(`https://172-236-28-14.ip.linodeusercontent.com/api/walk/content/`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const walkSlice = createSlice({
  name: "walk",
  initialState,
  reducers: {
    setPointActive: {
      prepare(waypoint: WaypointState) {
        return { payload: { waypoint } };
      },
      reducer(state, action: PayloadAction<{waypoint: WaypointState;}>) {
        state.waypoints.active = action.payload.waypoint;
      },
    },
  },
  extraReducers: (builder) => {
    builder

      // Get walks
      .addCase(getWalks.pending, (state) => {
        state.walks.loading = true;
      })
      .addCase(getWalks.fulfilled, (state, action) => {
        state.walks.data = action.payload.data;
        state.walks.loading = false;
      })
      .addCase(getWalks.rejected, (state) => {
        state.walks.loading = false;
      })

      // Get walk by ID
      .addCase(getWalkById.pending, (state) => {
        state.walk.loading = true;
      })
      .addCase(getWalkById.fulfilled, (state, action) => {
        state.walk.data = action.payload.data;
        state.walk.loading = false;
      })
      .addCase(getWalkById.rejected, (state) => {
        state.walk.loading = false;
      })

      // Get walk waypoints
      .addCase(getWalkWaypoints.pending, (state) => {
        state.waypoints.loading = true;
      })
      .addCase(getWalkWaypoints.fulfilled, (state, action) => {
        state.waypoints.data = action.payload.data;
        state.waypoints.loading = false;
      })
      .addCase(getWalkWaypoints.rejected, (state) => {
        state.waypoints.loading = false;
      })

      // Get walk content
      .addCase(getWalkContent.pending, (state) => {
        state.content.loading = true;
      })
      .addCase(getWalkContent.fulfilled, (state, action) => {
        state.content.data = action.payload.data;
        state.content.loading = false;
      })
      .addCase(getWalkContent.rejected, (state) => {
        state.content.loading = false;
      })
  },
});

export const { setPointActive } = walkSlice.actions;
export default walkSlice.reducer;