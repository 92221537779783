import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'


export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-white dark:bg-zinc-900 shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-24 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/">
                    <img
                      className="block h-16 w-auto lg:hidden"
                      src="/img/logo/icon.svg"
                      alt="Listen Queer"
                    />
                  </a>
                  <a href="/">
                    <img
                      className="hidden h-16 w-auto lg:block"
                      src="/img/logo/icon.svg"
                      alt="Listen Queer"
                    />
                  </a>
                </div>
                {/* <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  <a
                    href="/"
                    className="inline-flex items-center border-b-2 border-pink-400 px-1 pt-1 text-sm font-medium text-gray-900 hover:bg-slate-100"
                  >
                    Walks
                  </a>
                </div> */}
              </div>
            </div>
          </div>

        </>
      )}
    </Disclosure>
  )
}
