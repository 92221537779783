import React, { useEffect, useRef, forwardRef, memo } from 'react';
import point from './point.png';
import mapboxgl from 'mapbox-gl';
import directionArrow from './user.png';
import { haversineDistance } from '../../utils/haversine';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { setPointActive } from '../../slices/walk';
import { v4 as uuidv4 } from 'uuid';

const MapViewV3 = forwardRef((props, ref) => {
  const { features, coords, setFlyTo } = props;
  const dispatch = useAppDispatch();
  const mapContainer = useRef(null);
  const markerRef = useRef(null);
  const mapRef = useRef(null);
  const { data: waypointsData, active: activeWaypoint } = useAppSelector(
    (state) => state.walk.waypoints
  );

  const setActiveWaypoint = (waypoint) => dispatch(setPointActive(waypoint));

  let jsonData = [];
  let markers = [];
  let lines = [];

  if (features) {
    try {
      jsonData = JSON.parse(features.replaceAll("'", '"'));
      markers = jsonData.filter(
        (feature) => feature.geometry && feature.geometry.type === 'Point'
      );
      lines = jsonData.filter((x) => x.geometry.type === 'LineString');
    } catch (error) {}
  }

  const checkNearestLocation = (coords) => {
    const nearestLocation = waypointsData
      .map((marker) => {
        const coords2 = [marker.latitude, marker.longitude];
        let distance = haversineDistance(coords, coords2);
        distance *= 1000;
        return { ...marker, distance };
      })
      .sort((a, b) => a.distance - b.distance);

    for (const marker of nearestLocation) {
      if (marker.distance <= 20 && activeWaypoint !== marker) {
        setActiveWaypoint(marker);
        return;
      }
    }
  };

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYm9nbzIwMDIiLCJhIjoiY2x4OG85cTI2MHJyaDJrc2dlcTR0Mm1sYyJ9.yDSsc35DiCe3QGQoMZDzRA';
    const map = (mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [-0.1123, 51.503335],
      zoom: 14,
    }));

    map.on('load', async () => {
      const routeId = 'route_' + uuidv4();
      map.addSource(routeId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: lines,
        },
      });

      map.addLayer({
        id: 'route',
        type: 'line',
        source: routeId,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': 'black',
          'line-width': 5,
        },
      });

      if (coords) {
        const { latitude, longitude } = coords;

        // Create direction arrow marker
        const markerElement = document.createElement('div');
        markerElement.style.backgroundImage = `url(${directionArrow})`;
        markerElement.style.backgroundSize = 'cover';
        markerElement.style.width = '50px';
        markerElement.style.height = '50px';

        markerRef.current = new mapboxgl.Marker(markerElement)
          .setLngLat([longitude, latitude])
          .addTo(map);
      }

      // Point icons
      const pointImg = new Image(50, 50);
      pointImg.src = point;
      pointImg.onload = () => {
        map.addImage('point-marker', pointImg);

        // Points
        const pointsId = 'points_' + uuidv4();
        map.addSource(pointsId, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: markers,
          },
        });

        map.addLayer({
          id: 'pointMarker',
          type: 'symbol',
          source: pointsId,
          layout: {
            'icon-image': 'point-marker',
            'text-field': ['get', 'Name'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 1.25],
            'text-anchor': 'top',
          },
        });

        // Add click event for the markers.
        map.on('click', 'pointMarker', (e) => {
          const feature = e.features[0];
          const name = feature.properties.Name;
          const pointer = waypointsData.find((e) => e.name === name);

          if (pointer?.latitude && pointer?.longitude) {
            setActiveWaypoint(pointer);
            map.flyTo({
              center: [pointer.latitude, pointer.longitude],
              speed: 0.8,
              zoom: 19,
            });
          }
        });
      };

      if (typeof setFlyTo === 'function') {
        setFlyTo(() => (coords) => {
          mapRef.current.flyTo({
            center: coords,
            speed: 0.8,
            zoom: 19,
          });
        });
      }

      const intervalId = setInterval(() => {
        if (coords) {
          console.log(coords);
          const { latitude, longitude } = coords;
          markerRef.current.setLngLat([longitude, latitude]);
          checkNearestLocation([longitude, latitude]);
        }
      }, 5000);

      return () => {
        clearInterval(intervalId);
        if (markerRef.current) {
          markerRef.current.remove();
        }
        map.remove();
      };
    });
  }, [features, coords, setFlyTo]);

  return (
    <div
      ref={mapContainer}
      className='rounded-lg'
      style={{ width: '100%', height: 'calc(100vh - 360px)' }}
    />
  );
});

export default memo(MapViewV3);
