import React, { useEffect } from "react";
import MapView from "./MapView";
import { geolocated } from "react-geolocated";

interface MapViewProps {
  features: any;
  setFlyTo: any;
}

const MapViewGeolocated = geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 5000,
  },
  userDecisionTimeout: 5000,
  suppressLocationOnMount: false,
  geolocationProvider: navigator.geolocation,
  watchPosition: true,
  isOptimisticGeolocationEnabled: true,
})(MapView);

const MapViewWrapper = React.forwardRef<any, MapViewProps>((props, ref) => {
  return <MapViewGeolocated {...props} ref={ref} />;
});

export default MapViewWrapper;
