import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Header from "../layout/Header";
import Spinner from "../layout/Spinner";
import SpaceFiller from "../layout/SpaceFiller";
import Container from "../layout/Container";
import Card from "../layout/Card";
import Grid from "../layout/Grid";
import getIcon from "../../utils/icons";
import { getWalks } from "../../slices/walk";

export default function Walks() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getWalks());
  }, [dispatch]);
  
  const { data: walksData, loading: walksLoading } = useAppSelector((state) => state.walk.walks);
  const placeholders = [
    {
      id: 2,
      name: "Learn the Queer History of Brighton",
      description: "Join us for a walk or take a seat and enjoy a guided tour of Brighton's queer history.",
      image: "img/graphics/london-skyline.jpeg",
    },
    {
      id: 3,
      name: "Discover the daily life of a Queer Victorian Londoner",
      description: "Join us for a walk or take a seat and enjoy a guided tour of the daily life for a Queer Victorian Londoner.",
      image: "img/graphics/london-skyline.jpeg",
    },
    {
      id: 4,
      name: "Take a walk through Queer Manchester",
      description: "Join us for a walk or take a seat and enjoy a guided tour of Queer Manchester.",
      image: "img/graphics/london-skyline.jpeg",
    }
  ]

  return (
    <Template>

        {/* Walks array */}
        {walksLoading ? (
          <SpaceFiller>
            <Spinner/>
          </SpaceFiller>
        ): (
          <SpaceFiller className="overflow-y-auto scrollbar-invisible h-screen mt-10" verticalPosition="start">
            <Container className="justify-center">

                <Grid className="gap-8">
                  {walksData?.map((walk) => (
                    <a href={`/${walk.id}`} key={walk.id}>
                      <Card key={walk.id} className="shadow-sm" padding={0} colour="bg-white dark:bg-zinc-900">
                        <div className="w-full items-center justify-between space-x-6 p-4">
                          <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-4 gap-y-4">
                            <div className="col-span-1">
                              <img className="object-cover rounded-lg h-96 sm:h-auto sm:w-64 w-auto" src="img/graphics/london-skyline.jpeg" alt=""/>
                            </div>
                            <div className="col-span-2">
                              <h3 className="truncate text-md font-semibold text-gray-900 dark:text-white">{walk.name}</h3>
                              <p className="text-sm text-gray-500 dark:text-white">{walk.description}</p>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </a>
                  ))}
                  {placeholders.map((walk, idx) => (
                    <a href="#!" key={walk.id}>
                      <Card key={walk.id} className={`shadow-sm ${idx === placeholders.length - 1 && "mb-20"}`} padding={0} colour="bg-white dark:bg-zinc-900">
                        <div className="w-full items-center space-x-6 p-4">
                          <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-4 gap-y-4">
                            <div className="col-span-1">
                              <img className="object-cover rounded-lg h-96 sm:h-auto sm:w-64 w-auto" src="img/graphics/london-skyline.jpeg" alt=""/>
                            </div>
                            <div className="col-span-2">
                              <h3 className="truncate text-md font-semibold text-gray-900 dark:text-white">{walk.name}</h3>
                              <p className="text-sm text-gray-500 dark:text-white">{walk.description}</p>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </a>
                  ))}
                </Grid>


            </Container>
          </SpaceFiller>
        )}

    

    </Template>
  );
}