
interface GridProps {
  columnsLargeViewport?: number;
  columnsMediumViewport?: number;
  columnsSmallViewport?: number;
  defaultColumns?: number;
  className?: string;
  children: React.ReactNode;
  gapX?: number;
  gapY?: number;
}

export default function Grid({columnsLargeViewport, columnsMediumViewport, columnsSmallViewport, defaultColumns, className, children, gapX, gapY}: GridProps) {
  return (
    <div className={`grid grid-cols-${defaultColumns} sm:grid-cols-${columnsSmallViewport} md:grid-cols-${columnsMediumViewport} lg:grid-cols-${columnsLargeViewport} grid-flow-row-dense gap-x-${gapX} gap-y-${gapY} ${className}`}>
      {children}
    </div>
  ) 
}

Grid.defaultProps = {
  columnsLargeViewport: 3,
  columnsMediumViewport: 2,
  columnsSmallViewport: 1,
  defaultColumns: 1,
  className: '',
  gapX: 6,
  gapY: 6
}
