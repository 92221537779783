
interface CardProps {
  colour?: string;
  rounded?: string;
  className?: string;
  padding?: number;
  children: React.ReactNode;
}

export default function Card({colour, rounded, className, children, padding}: CardProps) {
  return (
    <div className={`${colour} rounded-${rounded} p-${padding} border border-transparent hover:border-slate-200 dark:hover:border-zinc-600 ${className}`}>
      {children}
    </div>
  ) 
}

Card.defaultProps = {
  colour: "white",
  rounded: "lg",
  className: "",
  padding: 4
}
