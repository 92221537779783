import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Header from "../layout/Header";
import Spinner from "../layout/Spinner";
import SpaceFiller from "../layout/SpaceFiller";
import Container from "../layout/Container";
import {
  WaypointState,
  getWalkById,
  getWalkContent,
  getWalkWaypoints,
  setPointActive,
} from "../../slices/walk";
import { useParams } from "react-router-dom";
import { ArrowLongRightIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { Carousel, IconButton } from "@material-tailwind/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import MapViewWrapper from "../map/MapViewWrapper";

export default function WalkDetail() {
  const [flyTo, setFlyTo] = useState<any | null>(null);

  const dispatch = useAppDispatch();
  const { walk_id: walkId } = useParams();
  useEffect(() => {
    dispatch(getWalkById({ walkId }));
    dispatch(getWalkWaypoints({ walkId }));
    dispatch(getWalkContent({ walkId }));
  }, [dispatch, walkId]);

  const [toggleKeypad, setToggleKeypad] = useState(false);
  const { data: walkData, loading: walkLoading } = useAppSelector(
    (state) => state.walk.walk
  );
  const {
    data: waypointsData,
    loading: waypointsLoading,
    active: activeWaypoint,
  } = useAppSelector((state) => state.walk.waypoints);
  const { data: contentData, loading: contentLoading } = useAppSelector(
    (state) => state.walk.content
  );

  // Fly to waypoint

  const handleFlyTo = (coords: any) => {
    if (typeof flyTo === "function") {
      flyTo(coords);
    }
  };

  function setActiveWaypoint(e: any, waypoint: WaypointState) {
    e.preventDefault();
    dispatch(setPointActive(waypoint));
    if (!waypointsLoading) handleFlyTo([waypoint.latitude, waypoint.longitude]);
    setToggleKeypad(false);
  }

  const waypointContent =
    contentData === null
      ? []
      : contentData?.filter(
          (content) => content.waypoint === activeWaypoint?.id
        );
  const waypointImages =
    waypointContent.length === 0
      ? []
      : waypointContent?.filter((content) => content.content_type === "IMG");
  const waypointAudio =
    waypointContent.length === 0
      ? []
      : waypointContent?.filter((content) => content.content_type === "AUD");
  const waypointTranscripts =
    waypointContent.length === 0
      ? []
      : waypointContent?.filter((content) => content.content_type === "TRN");
    
  return (
    <Template>
      {/* Keypad map locations */}
      <div className="on-top fixed bottom-0 right-0 items-end flex flex-col">
        {toggleKeypad && (
          <SpaceFiller
            className="bg-white dark:bg-zinc-900 shadow rounded-lg relative right-10 max-w-xs overflow-y-auto h-96"
            verticalPosition="start"
          >
            <ul className="divide-y divide-gray-100 h-96">
              {waypointsData?.map((wp) => (
                <li
                  key={wp.id}
                  className="flex justify-between gap-x-3 p-2 hover:bg-slate-50 dark:hover:bg-zinc-700"
                >
                  <a href="#!" onClick={(e) => setActiveWaypoint(e, wp)}>
                    <div className="flex gap-x-4">
                      {wp.type === "P" ? (
                        <>
                          <MapPinIcon
                            className="h-6 w-6 text-pink-400"
                            aria-hidden="true"
                          />
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-normal leading-6 text-gray-900 dark:text-white">{`${wp.order}. ${wp.name}`}</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-white">
                              {wp.description}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <ArrowLongRightIcon
                            className="h-6 w-6 text-black dark:text-white"
                            aria-hidden="true"
                          />
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-normal leading-6 text-gray-900 dark:text-white">{`${wp.order}. ${wp.name}`}</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-white">
                              {wp.description}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </SpaceFiller>
        )}

        <button
          type="button"
          className="mr-3 my-3 inline-flex items-center rounded-full border border-transparent p-6 text-white dark:text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-offset-2 bg-pink-400"
          onClick={(e) => setToggleKeypad(!toggleKeypad)}
        >
          <MapPinIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      {/* Header */}
      <Header
        text={walkLoading || walkData === null ? "Walk" : walkData.name}
        description={
          !walkLoading || walkData !== null ? walkData?.description : null
        }
      />

      {/* Walk data */}
      {walkLoading || waypointsLoading || contentLoading ? (
        <SpaceFiller className="flex-1">
          <Spinner />
        </SpaceFiller>
      ) : (
        <SpaceFiller
          className="overflow-y-auto scrollbar-invisible h-screen"
          verticalPosition="start"
        >
          <Container className="justify-center">
            {walkData !== null && (
              <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-4 gap-y-4">
                {/* Map */}
                <div
                  className={
                    activeWaypoint === null ? "col-span-3" : "col-span-2"
                  }
                >
                  <MapViewWrapper
                    features={walkData.geojson_features}
                    setFlyTo={setFlyTo}
                  />
                </div>

                {/* Content carousel */}
                {activeWaypoint !== null && (
                  <div className="sm:mt-0 mt-10 col-span-1 rounded-lg overflow-x-clip overflow-y-auto scrollbar-invisible h-[550px] mb-10">
                    <h3 className="sm:mt-0 mt-8 mb-4 text-xl font-bold leading-7 text-gray-900 dark:text-white sm:text-2xl sm:tracking-tight">
                      {activeWaypoint.name}
                    </h3>
                    {waypointImages.length > 0 && (
                      <div className="h-80 bg-white dark:bg-zinc-900 rounded-lg p-4 pb-6">
                        <Carousel
                          className="rounded-lg gap-x-2"
                          navigation={({
                            setActiveIndex,
                            activeIndex,
                            length,
                          }) => (
                            <div className="absolute -bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                              {new Array(length).fill("").map((_, i) => (
                                <span
                                  key={i}
                                  className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                    activeIndex === i
                                      ? "bg-slate-500 w-8"
                                      : "bg-slate-200 w-4"
                                  }`}
                                  onClick={() => setActiveIndex(i)}
                                />
                              ))}
                            </div>
                          )}
                          prevArrow={({ handlePrev }) => (
                            <IconButton
                              variant="text"
                              color="white"
                              size="lg"
                              onClick={handlePrev}
                              className="!absolute top-2/4 -translate-y-2/4 left-4"
                            >
                              <ArrowLeftIcon
                                strokeWidth={2}
                                className="text-white w-6 h-6"
                              />
                            </IconButton>
                          )}
                          nextArrow={({ handleNext }) => (
                            <IconButton
                              variant="text"
                              color="white"
                              size="lg"
                              onClick={handleNext}
                              className="!absolute top-2/4 -translate-y-2/4 !right-4"
                            >
                              <ArrowRightIcon
                                strokeWidth={2}
                                className="w-6 h-6"
                              />
                            </IconButton>
                          )}
                        >
                          {waypointImages.map((content) => (
                            <img
                              key={content.id}
                              src={content.url}
                              alt=""
                              className="h-full w-full object-cover rounded-lg mr-2"
                            />
                          ))}
                        </Carousel>
                      </div>
                    )}
                    {waypointAudio.length > 0 && (
                      <div className="mt-4 mb-2 h-16" id="audio-parent">
                        <audio
                          key={waypointAudio[0].id}
                          id="audio-player"
                          className="block w-full max-w-md mx-auto"
                          controls
                          autoPlay
                        >
                          <source
                            id="audio-source"
                            src={waypointAudio[0].url}
                            type="audio/mpeg"
                          />
                        </audio>
                      </div>
                    )}
                    {waypointTranscripts.length > 0 && (
                      waypointTranscripts[0].content_text.length === 0 ? null : (
                        <div className="h-80 bg-white dark:bg-zinc-900 dark:text-white rounded-lg p-4 pb-6 overflow-y-auto mb-10">
                          <p>{waypointTranscripts[0].content_text}</p>
                        </div>
                      ) 
                    )}
                  </div>
                )}
              </div>
            )}
          </Container>
        </SpaceFiller>
      )}
    </Template>
  );
}
